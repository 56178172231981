<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer2 mt-150">
      <validation-observer ref="simpleRules">
        <div class="mb-2">
          <router-link :to="{name : 'export'}">
            <b-button
              variant="primary"
              class="mr-50 col-md-3 col-lg-2 col-sm-12"
            >
              {{ $t('returnToList') }}
            </b-button>
          </router-link>
          <b-button
            variant="secondary"
            class="mr-50 col-md-2 mt-1 mt-lg-0 mt-md-0"
            @click="printpdf()"
          >
            {{ $t('print') }}
          </b-button>
        </div>

        <b-row class="d-flex">
          <b-col
            md="6"
            lg="6"
            sm="12"
          >
            <div class="card p-2">
              <h4 class="font-weight-bolder mb-1">
                # {{ $t('orderNumber') }} {{ items.itemnumber_text }}
              </h4>
              <div class="d-flex align-items-center mb-1">
                <div class="w-25 mt-1">
                  <h4 class="font-weight-bolder mb-1">
                    {{ $t('status') }}
                  </h4>
                </div>
                <div
                  v-if="items.payNow === 2 && items.order_out === 1"
                  class="w-50 badge badge-light-danger col-md-3"
                >
                  {{ $t('awaitingPickup') }}
                </div>
                <div
                  v-if="items.payNow === 2 && items.order_out === 2"
                  class="w-50 badge badge-light-success col-md-3"
                >
                  {{ $t('receivedItem') }}
                </div>
                <div
                  v-if="items.payNow === 2 && items.order_thaiout === 1"
                  class="w-50 badge badge-light-primary col-md-3"
                >
                  {{ $t('awaitingDeliveryTH') }}
                </div>
                <div
                  v-if="items.payNow === 2 && items.order_thaiout === 2"
                  class="w-50 badge badge-light-success col-md-3"
                >
                  {{ $t('key-123') }}

                </div>
                <div
                  v-if="items.payNow === 3"
                  class="w-50 badge badge-light-secondary col-md-3"
                >
                  {{ $t('cancelled') }}
                </div>
                <!-- <p
                  v-if="items.payNow === 3"
                  style="color:red;"
                >
                  *{{ $t('canceledBy') }} {{ items.by_Cancel }}
                </p> -->
              </div>
              <h4 class="font-weight-bolder mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-map-pin"
                ><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle
                  cx="12"
                  cy="10"
                  r="3"
                /></svg>{{ $t('recipientAddress') }}
              </h4>

              <div>
                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('key-77') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.address.fullname }}
                  </div>
                </div>

                <div class="d-flex mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('address') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.address.address }} / {{ items.address.subdistrict }} / {{ items.address.district }} / {{ items.address.province_name }} / {{ items.address.postalcode }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('key-81') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.address.tel }}
                  </div>
                </div>

                <div class="d-flex mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('deliveryType') }}
                    </p>
                  </div>

                  <div class="w-50">
                    <span class="d-flex">{{ items.typedelivery.pro_name }} <p style="color:red;"> ({{ items.typedelivery.paydelivery }})</p></span>
                  </div>
                </div>
                <div

                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('remarks') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.remark ? items.remark: '-' }}
                  </div>
                </div>
                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('sender') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.delivery.name }}
                  </div>
                </div>
                <div
                  v-if="items.delivery.id === 2"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('address') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ $t('receivedNotseeprice') }}
                  </div>
                </div>

                <div
                  v-if="items.delivery.id === 3"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('address') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.delivery.address }}
                  </div>
                </div>
                <div
                  v-if="items.delivery.id === 3"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('key-81') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.delivery.tel }}
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="col-md-5 col-lg-5 col-sm-12">
            <div class="card p-2">
              <h4 class="font-weight-bolder mb-1">
                {{ $t('exportInthai') }}
              </h4>
              <div>
                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('exportTrackingNumber') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.postnumber_th }}
                  </div>
                </div>

                <div class="d-flex mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('shippingcosts') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ Commas(items.Shipping_costth) }}
                  </div>
                </div>

                <div class="d-flex align-items-center mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('remarks') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ items.remark_th }}
                  </div>
                </div>

                <div class="d-flex mb-1">
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('transactionEvidence') }}
                    </p>
                  </div>

                  <div class="w-50">
                    <img
                      v-if="fileimg_th"
                      :src="GetImg('paymentfile',fileimg_th)"
                      accept=".jpg, .png, .jpeg"
                      alt="รูปภาพ"
                      class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                      @click="showPicture(fileimg_th)"
                    >
                  </div>
                </div>
                <div

                  class="d-flex align-items-center mb-1"
                >
                  <div class="w-50">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('exportDate') }}
                    </p>
                  </div>

                  <div class="w-50">
                    {{ FormateDateTh(items.out_thaiwarehouse) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card p-2">
              <h4 class="font-weight-bolder mb-0">
                {{ $t('termsAndConditions') }}
              </h4>
              <div>
                <h6 class="mb-1">
                  {{ $t('receiveInstructions') }}
                </h6>
              </div>
              <div>
                <P>
                  {{ $t('receiveInstructions1') }}
                </P>
                <P>
                  {{ $t('receiveInstructions2') }}
                </P>
                <P>
                  {{ $t('receiveInstructions3') }}
                </P>
                <P>
                  {{ $t('receiveInstructions4') }}
                </P>
                <P>
                  {{ $t('receiveInstructions5') }}
                </P>
              </div>
              <div>
                <b-form-checkbox
                  v-model="stickyHeader"
                  disabled
                  class="custom-control-primary"
                >
                  <span class="font-weight-bolder text-black">{{ items.username }} {{ $t('termsAgreement') }}</span>
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <div class="card">
              <div class="header-cardx p-2">
                <h3 class="mb-0">
                  {{ $t('parcelCodeList') }}
                </h3>
              </div>

              <div class="table-container">
                <table
                  class="styled-table mt-n25"
                >
                  <thead>
                    <tr>
                      <th>
                        {{ $t('lotSequence') }}
                      </th>

                      <th>
                        {{ $t('parcelCode') }}
                      </th>

                      <th>
                        {{ $t('orderList') }}
                      </th>

                      <th>
                        {{ $t('mnemonic') }}
                      </th>

                      <!-- <th>
                        {{ $t('arrivedThailand') }}
                      </th> -->

                      <th>
                        {{ $t('quantity') }}
                      </th>

                      <th>
                        {{ $t('weight') }}
                      </th>

                      <th>
                        {{ $t('widthCm') }}
                      </th>

                      <th>
                        {{ $t('heightCm') }}
                      </th>

                      <th>
                        {{ $t('lengthCm') }}
                      </th>

                      <th>
                        {{ $t('cubic') }}
                      </th>
                      <th>
                        {{ $t('arrange') }}
                      </th>

                      <th>
                        {{ $t('type') }}
                      </th>

                      <th>
                        {{ $t('serviceFee') }}
                      </th>

                      <th>
                        {{ $t('pricePerKilo') }}
                      </th>

                      <th>
                        {{ $t('pricePerCubic') }}
                      </th>
                    </tr>
                  </thead>
                  <thead class="text-center">
                    <tr style="background: #fff; color: black;">
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b />
                      </th>
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b />
                      </th>
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b />
                      </th>
                      <!-- <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b />
                      </th> -->
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b>{{ $t('total') }}</b>
                      </th>
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b>{{ Commas(postnumber.reduce((total,item) => total + item.product_amount,0)) }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b>{{ Commas(postnumber.reduce((total,item) => total + (item.weight*item.product_amount),0)) }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b>{{ Commas(postnumber.reduce((total,item) => total + item.width,0)) }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b>{{ Commas(postnumber.reduce((total,item) => total + item.height,0)) }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b>{{ Commas(postnumber.reduce((total,item) => total + item.long,0)) }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <b>{{ CommasCue(postnumber.reduce((total,item) => total + (item.charge_cue*item.product_amount),0)) }}</b>
                      </th>
                      <th
                        scope="col"
                        style="background: #fff"
                      />
                      <th
                        class="text-center"
                        style="background: #fff; color: black;"
                      >
                        <b />
                      </th>
                      <th
                        class="text-center"
                        style="background: #fff; color: black;"
                      >
                        {{ sumall = Commas(postnumber.reduce((total,item) => total + (item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee),0)) }}
                        <b />
                      </th>
                      <th
                        class="text-center"
                        style="background: #fff; color: black;"
                      >
                        {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_kilo === item.charge_per_save ? item.charge_per_save: 0 ),0)) }}
                        <b />
                      </th>
                      <th
                        class="text-center"
                        style="background: #fff; color: black;"
                      >
                        {{ Commas(postnumber.reduce((total,item) => total + (item.charge_per_cue === item.charge_per_save ? item.charge_per_save: 0 ),0)) }}
                        <b />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(item,index) in postnumber"
                      :key="index"
                    >
                      <td style="width: 300px !important;">
                        <i
                          v-if="item.transportType === 1"
                          class="fas fa-truck-moving"
                        />
                        <i
                          v-if="item.transportType === 2"
                          class="fas fa-ship"
                        />

                        {{ item.lot_name }} / {{ item.lot_order }}
                      </td>
                      <td>{{ item.postnumber }}</td>
                      <td
                        scope="col"
                        class="text-left"
                        style="
                        width: 7% !important;"
                      >

                        <router-link
                          v-if="item.order_text"
                          :to="{name : 'order-detail', params: {id: item.order_id}}"
                          class="mb-0"
                        >
                          {{ `ORD-${item.order_text}` }}
                        </router-link>
                        <span v-else>
                          -
                        </span>

                      </td>
                      <td
                        scope="col"
                        class="text-left"
                        width="400px"
                      >
                        <div
                          class="text-truncate"
                          style="max-width: 400px !important;"
                        > {{ item.keyword ? item.keyword : '-' }} </div>
                      </td>
                      <!-- <td>{{ item.out_thaiwarehouse }}</td> -->
                      <td>{{ Commas(item.product_amount) }}</td>
                      <td>{{ Commas(item.weight*item.product_amount) }}</td>
                      <td>{{ Commas(item.width) }}</td>
                      <td>{{ Commas(item.height) }}</td>
                      <td>{{ Commas(item.long) }}</td>
                      <td>{{ CommasCue(item.charge_cue*item.product_amount) }}</td>
                      <td>{{ item.collocate_wh_name ? item.collocate_wh_name: '-' }}</td>
                      <td style="width: 50px">
                        {{ item.product_type.pro_initial }}
                      </td>
                      <td>{{ Commas(item.product_inspection_fee + item.re_wrap_fee + item.shipping_fee + item.other + item.packaging_fee) }}</td>
                      <td>{{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</td>
                      <td>{{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-' }}</td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr>
                      <td
                        colspan="13"
                        class="text-right font-weight-bolder"
                      >
                        {{ $t('total') }}
                      </td>

                      <td
                        colspan="3"
                        class="text-right"
                      >
                        <b
                          v-if="items.coupon"
                        >
                          <b style="text-decoration: line-through; color: red;">{{ Commas(postnumber.reduce((total,item) => total + item.charge_per_save +item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee,0)) }} ฿</b>
                          {{ Commas((postnumber.reduce((total,item) => total + item.charge_per_save + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee,0)- items.coupon.discount_coupon)) }} ฿

                        <!--  - arritem.coupon.discount -->
                        </b>
                        <b
                          v-else
                        >{{ Commas(postnumber.reduce((total,item) => total + item.charge_per_save + item.other + item.shipping_fee + item.product_inspection_fee + item.re_wrap_fee + item.packaging_fee,0)) }} ฿</b>

                      </td>
                    </tr>
                    <tr>
                      <td
                        colspan="13"
                        class="text-right font-weight-bolder"
                      >
                        {{ $t('discountCoupon') }}
                      </td>

                      <td
                        colspan="3"
                        class="text-right"
                      >
                        <b
                          v-if="items.coupon"
                          style="color: red; "
                        >{{ Commas(items.coupon.discount_coupon) }} ฿</b>
                        <b
                          v-else
                        >0 ฿</b>
                      </td>
                    </tr>

                    <tr>
                      <td
                        colspan="13"
                        class="text-right font-weight-bolder"
                      >
                        {{ $t('thaiWarehouseFee') }}
                      </td>

                      <td
                        colspan="3"
                        class="text-right"
                      >
                        {{ Commas(items.Service_fee) }} ฿
                      </td>
                    </tr>

                    <tr>
                      <td
                        colspan="13"
                        class="text-right font-weight-bolder"
                      >
                        <small class="text-danger">{{ $t('estimatedCostNote') }}</small>   {{ $t('domesticDeliveryFee') }}
                      </td>

                      <td
                        colspan="3"
                        class="text-right"
                      >

                        <b
                          v-if="items.promotion"
                        >
                          <b
                            style="text-decoration: line-through; color: red;"
                          >{{ Commas(items.promotion.old_delivery) }} ฿</b>
                          <b> {{ Commas(items.promotion.discount_pro) }} ฿</b>
                        </b>

                        <b
                          v-else
                        >{{ Commas(items.Shipping_cost) }} ฿ </b>
                      </td>
                    </tr>

                    <tr>
                      <td
                        colspan="13"
                        class="text-right font-weight-bolder"
                      >
                        {{ $t('netAmount') }}
                      </td>

                      <td
                        colspan="3"
                        class="text-right h3"
                      >
                        {{ Commas(items.payment) }} ฿
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <div class="col-md-6">
            <div class="card">
              <div class="header-cardx p-2">
                <div class="d-flex align-items-center justify-content-between mb-2">
                  <h4 class="font-weight-bolder">
                    {{ $t('relatedAmountList') }}
                  </h4>
                </div>

                <table class="styled-table mt-n25">
                  <thead>
                    <tr>
                      <th scope="col">
                        {{ $t('transactionDate') }}
                      </th>
                      <th
                        class="text-left"
                        scope="col"
                      >
                        {{ $t('details') }}
                      </th>
                      <th scope="col">
                        {{ $t('quantity') }}
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr
                      v-for="(arritem,index) in record"
                      :key="index"
                      style="background: #fff; color: black;"
                    >
                      <th
                        class="text-center"
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        {{ time(arritem.created_at) }}
                      <!-- 09 ตุลาคม 2023 13:16:12 -->
                      </th>
                      <th
                        class="text-left"
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        {{ arritem.detail }}
                      <!-- รายการชำระค่านำเข้า PAY-10727 -->
                      </th>
                      <th
                        class="text-right"
                        scope="col"
                        style="background: #fff; color: black;"
                      >
                        <span :style="getAmountStyle(arritem.type)">
                          {{ getAmountPrefix(arritem.type) }} {{ Commas(arritem.amount.toFixed(2)) }} ฿
                        </span>
                      <!-- {{ arritem.amount }} -->
                      <!-- -9.00 ฿ -->
                      </th>
                    </tr>
                  </thead>
                </table>
                <div class="d-flex align-items-center justify-content-start p-2" />
              </div>
            </div>
          </div>
        </b-row>
      </validation-observer></div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    ValidationObserver,
    BFormCheckbox,
    VueImageLightboxCarousel,
    // ThisHeader,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      email,
      weight: 0,
      width: 0,
      height: 0,
      file: null,
      ID: this.$route.params.id,
      items: [],
      address: [],
      showOver: false,
      fileimg_th: null,
      Shipping: 0,
      check_shipping: 1,
      postnumber: [],
      record: [],
      stickyHeader: true,
      sumall: 0,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getData()
    this.getbalance()
  },
  methods: {
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('paymentfile', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    getData() {
      this.showOver = true
      const params = {
        id: this.ID,
      }
      this.$http.get('/Paymentuser/ExportOne', { params })
        .then(response => {
          this.items = response.data
          if (this.items.fileimg_th) {
            this.fileimg_th = this.items.fileimg_th
          }

          if (this.items.Shipping_cost > this.items.Shipping_costth) {
            this.Shipping = this.items.Shipping_cost - this.items.Shipping_costth
            this.check_shipping = 2
          }
          if (this.items.Shipping_cost < this.items.Shipping_costth) {
            this.Shipping = this.items.Shipping_costth - this.items.Shipping_cost
            this.check_shipping = 1
          }
          if (this.items.out_thaiwarehouse) {
            const parts = this.items.out_thaiwarehouse.split('/')
            const day = parts[0]
            const month = parts[1]
            const year = parts[2]
            this.items.out_thaiwarehouse = `${year}-${month}-${day}`
          }
          this.getPostnumber(this.items)
          this.showOver = false
        })
        .catch(error => console.log(error))
    },
    printpdf() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        // window.open(`https://api.p2cargo.com/api/print/ExportProductsCusPdf/${this.ID}`)
        window.open(`https://api.p2cargo.com/api/print/ExportProductsCusPdf/${this.ID}`)
      } catch (e) {
        console.log(e)
      }
    },
    getPostnumber(item) {
      const params = {
        data: item.postnumber_id,
      }
      this.$http.get('/StatusPayment/getPostnumber', { params })
        .then(response => {
          this.postnumber = response.data
        })
        .catch(error => console.log(error))
    },
    async getbalance() {
      const params = {
        id: this.ID,
      }
      this.$http.get('/StatusPayment/getBalance', { params })
        .then(response => {
          this.record = response.data
        })
        .catch(error => console.log(error))
    },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16].includes(amount)) {
          return { color: 'red' }
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: 'green' }
        }
      }
      return { color: 'orange' }
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16].includes(value)) {
          return '-'
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return '+'
        }
      }
      return ''
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.custom-control-primary .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgb(0 85 255) !important;
  border: none;
  box-shadow: none !important;
}
</style>
